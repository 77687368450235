import React from 'react';
import { Admin, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { DataLister } from './DataLister';

const MasterDashboardTable = () => {
  return (
    <Admin
      basename="/admin"
      dataProvider={simpleRestProvider('http://localhost:8080/general/master')}
    >
      <Resource name="transactions" list={DataLister} />
    </Admin>
  );
};

export default MasterDashboardTable;
