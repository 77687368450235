import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDrag } from 'react-dnd';
import { StyledCardHeader } from './StandardCardElements';
import { CurrencyExchangeSharp } from '@mui/icons-material';

const StandardCard = ({ id, info, descriptor }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'card',
    item: { id: id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Card
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        fontSize: 15,
        fontWeight: 'bold',
        cursor: 'move',
        backgroundColor: 'fff',
      }}
      sx={{
        alignSelf: 'center',
        width: {
          xs: 150,
          sm: 175,
          md: 195,
          lg: 210,
          xl: 250,
        },
        height: {
          xs: 150,
          sm: 175,
          md: 195,
          lg: 210,
          xl: 250,
        },
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '1em',
        borderRadius: '15px',
      }}
      elevation={3}
    >
      <CardHeader
        sx={{ paddingBottom: 0 }}
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={
          <Typography
            sx={{
              paddingBottom: {
                xs: 0,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 2,
              },
            }}
            variant="h6"
            color="text.secondary"
          >
            <CurrencyExchangeSharp
              sx={{
                fontSize: {
                  xs: 6,
                  sm: 12,
                  md: 20,
                  lg: 30,
                  xl: 35,
                },
              }}
            />
          </Typography>
        }
      />
      <CardContent
        sx={{
          paddingBlockStart: 0,
          display: 'flex',
          flexDirection: {
            xs: 'row',
            sm: 'column',
            md: 'column',
            lg: 'column',
            xl: 'column',
          },
          gap: '.5em',
          whiteSpace: 'nowrap',
        }}
      >
        <Typography variant="h4" color="text.primary">
          {info}
        </Typography>
        <Typography variant="h5" color="text.secondary">
          {descriptor}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StandardCard;
