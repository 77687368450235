import { useContext } from 'react';
import React from 'react';
import {
  StyledDrawer,
  StyledIconContainer,
  StyledDrawerContentBox,
  StyledDrawerHeader,
  StyledStandardCardView,
  StyledCardViewContainer,
} from './PermDrawerElements';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { useTheme, IconButton } from '@mui/material';
import { ColorModeContext, tokens } from '../../styles/Theme';
import Avatar from '@mui/material/Avatar';
import { DarkModeOutlined } from '@mui/icons-material';

const SideNav = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  console.log(theme.palette.mode);

  return (
    <StyledDrawer sx={{ backgroundColor: colors.grey[900] }}>
      <StyledIconContainer>
        <IconButton>
          <NotificationsNoneOutlinedIcon
            sx={{ fontSize: 30, color: colors.iconColor[500] }}
          />
        </IconButton>
        <IconButton>
          <Avatar sx={{ color: colors.iconColor[500] }}>A&B </Avatar>
        </IconButton>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? (
            <LightModeOutlinedIcon sx={{ fontSize: 30 }} />
          ) : (
            <DarkModeOutlined sx={{ fontSize: 30 }} />
          )}
        </IconButton>
      </StyledIconContainer>

      <StyledDrawerContentBox>
        <StyledDrawerHeader> Placeholder </StyledDrawerHeader>

        <StyledCardViewContainer>
          <StyledStandardCardView />
          <StyledStandardCardView />
          <StyledStandardCardView />
          <StyledStandardCardView />
        </StyledCardViewContainer>
      </StyledDrawerContentBox>

      <StyledDrawerContentBox sx={{ flex: 2 }}>
        <StyledCardViewContainer>
          <StyledStandardCardView />
          <StyledStandardCardView />
          <StyledStandardCardView />
          <StyledStandardCardView />
        </StyledCardViewContainer>
      </StyledDrawerContentBox>
    </StyledDrawer>
  );
};

export default SideNav;
