import { ResponsiveBar } from '@nivo/bar';
import { useEffect } from 'react';
import { mockBarChart as data, mockBarChart } from '../../api/mockData';
import { useSelector, useDispatch } from 'react-redux';
import { change } from '../../redux/chartSlice';
import { useGetAllBarChartDataQuery } from '../../redux/apiSlice';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import React from 'react';

const BarChart = (): JSX.Element => {
  const { data, isLoading, isSuccess, isError } =
    useGetAllBarChartDataQuery(null);

  // console.log({ data, isLoading, isSuccess, isError });

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <CircularProgress size={85} color="inherit" />
      </Box>
    );
  } else if (isSuccess) {
    return (
      <ResponsiveBar
        data={data}
        keys={['Reachouts', 'Responses']}
        indexBy="weekEnd"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        groupMode="grouped"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        // colors={({ id, data }) => String(data[`${id}Color`])}
        colors={['#61d4ff', 'hsl(93, 70%, 50%)']}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.5]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Week End',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Reachout Count',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipHeight={10}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Bar Chart"
        barAriaLabel={function (e) {
          return (
            e.id + ': ' + e.formattedValue + ' in Week End: ' + e.indexValue
          );
        }}
      />
    );
  } else {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <h1>
          Sorry, an error occured while loading your chart. We are working on
          fixing this. Please come back later or contact our customer support so
          we can check on this for you.
        </h1>
      </Box>
    );
  }

  // useEffect(() => {
  //   console.log(chartData);
  //   dispatch(change({ value: ['work'], status: 'loading', error: null }));
  // }, []);
};

export default BarChart;
