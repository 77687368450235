import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  value: [],
  status: 'idle',
  error: null,
};

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    change: (state, action) => {
      state.value = action.payload;
    },
  },
});

// export const fetchChart = createAsyncThunk('chart/fetchChart', async () => {
//   const response = await fetchChartData('/general/barchart');
//   console.log(response);
//   console.log('here');
//   return response.data;
// });
export const { change } = chartSlice.actions;

export default chartSlice.reducer;
