import styled from 'styled-components';
import { Box, Paper, Typography } from '@mui/material';

export const StyledDrawer = styled(Box)`
  height: 95%;
  width: 90%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  margin-top: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  gap: 2em;
  /* border: 5px solid gold; */
`;

export const StyledIconContainer = styled(Box)`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  gap: 3em;
  padding: 1em;
  /* border: 1px solid red; */
`;

export const StyledIconBox = styled(Box)`
  /* border: 1px solid red; */
  flex: 1;
`;

export const StyledAssetContainer = styled(Box)`
  /* width: 20px; */
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 20px; */
`;

export const StyledDrawerHeader = styled.h2`
  /* border: 1px solid red; */
  color: #56537a;
  font-family: 'Geist Font';
  font-weight: 800;
  letter-spacing: 0.125rem;
  font-size: 1.75rem;
  margin-bottom: 1em;
  margin-top: 1em;
  margin-left: 0.5em;
`;

export const StyledDrawerContentBox = styled(Box)`
  /* border: 1px solid red; */
  padding: 1em;
  flex: 1;
`;

export const StyledNavImg = styled.img`
  height: 80px;
  width: 80px;
`;

export const StyledGeistlyText = styled.h1`
  font-family: 'Geist Font';
  font-size: 50px;
`;

export const StyledStandardCardView = styled(Paper)`
  height: 40px;
  width: 80%;
  opacity: 60%;
`;

export const StyledMediumCardView = styled(Paper)`
  height: 38px;
  width: 70px;
`;

export const StyledLargeCardView = styled(Paper)`
  height: 80px;
  width: 120px;
`;

export const StyledCardViewContainer = styled(Box)`
  /* border: 1px solid green; */
  padding: 0.2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  justify-content: center;
`;

export const StyledMediumCardViewWrapper = styled(Box)`
  /* border: 1px solid gold; */
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;
