import * as React from 'react';
import { List, Datagrid, TextField, EmailField } from 'react-admin';
import CustomUrlField from './CustomUrlField';

export const DataLister = () => (
  <List>
    <Datagrid rowClick="edit">
      {/* <TextField source="id" />
      <TextField source="name" /> */}
      <TextField source="transact_date" />
      <TextField source="last_name" />
      <TextField source="item_id" />
      <TextField source="reachout" />
      <TextField source="first_name" />
      <TextField source="SK" />
      <TextField source="PK" />
      <TextField source="email" />
      <TextField source="phone" />
      <TextField source="anonymous" />
      <TextField source="question_id" />
      <TextField source="response" />

      {/* <EmailField source="email" /> */}
      {/* <CustomUrlField source="website" /> */}
      {/* <TextField source="company.name" /> */}
    </Datagrid>
  </List>
);
