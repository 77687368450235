import { createGlobalStyle } from 'styled-components';

import GeistBold from './GeistBold.otf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Geist Font';
        src: local('Geist Font'), local('GeistFont'),
        url(${GeistBold}) format('otf');
        font-weight: 800;
        font-style: normal;
    }
`;
