import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { toBePartiallyChecked } from '@testing-library/jest-dom/dist/matchers';

export const StyledWrapperBox = styled(Box)`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  max-width: 70vw;
`;

export const StyledGrid = styled(Grid)`
  /* border: 1px solid black; */
`;

export const StyledDashboardWrapper = styled(Box)`
  /* border: 1px solid teal; */
  display: flex;
  flex-direction: column;

  padding: 1.5em;
  min-width: 100%;
  min-height: 90%;
  align-self: center;
`;

export const StyledCardContainer = styled(Box)`
  display: flex;
  flex: 0.8;
  padding: 0em;
  gap: 1em;
  /* border: 2px solid green; */
`;

export const StyledChartContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
`;

export const StyledChartIconContainer = styled(Box)`
  display: flex;
  width: 10%;
  align-self: center;
  justify-content: center;
  align-items: center;
  /* background-color: #fafafa; */
  margin-top: 0.5em;
  border-radius: 5px;
  padding-block-start: 0.2em;
  /* border: 1px solid green; */
`;

export const StyledInnerLgContainer = styled(Box)`
  /* border: 1px solid/ red; */
  flex: 2;
  display: flex;
  justify-content: center;
`;
export const StyledInnerMdContainer = styled(Box)`
  /* border: 1px solid gold; */
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  gap: 1em;
`;
