import { useContext } from 'react';
import React from 'react';
import {
  StyledSideNav,
  StyledIconContainer,
  StyledIconBox,
  StyledAssetContainer,
  StyledNavImg,
  StyledNavFooterImg,
  StyledNavLink,
} from './SideNavElements';
import { navData } from './navData';
import geistPurpleLogo from '../../assets/logoSvg/logoPurple.svg';
import geistBlackLogo from '../../assets/logoSvg/logoBlack.svg';
import geistWhiteLogo from '../../assets/logoSvg/logoWhite.svg';
import geistTechLetteringWhite from '../../assets/logoWithText/logoTechLetteringWhite.png';
import { NavLink } from 'react-router-dom';
import { useTheme, IconButton, Icon } from '@mui/material';
import { ColorModeContext, tokens } from '../../styles/Theme';

const SideNav = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const modeLogo =
    theme.palette.mode === 'light' ? geistWhiteLogo : geistBlackLogo;

  return (
    <StyledSideNav sx={{ backgroundColor: colors.primary[500] }}>
      <StyledAssetContainer>
        <StyledNavImg src={modeLogo} alt="Geist Logo" />
      </StyledAssetContainer>
      <StyledIconContainer>
        {navData.map((item) => {
          return (
            <StyledNavLink key={item.id} to={item.link}>
              {item.icon}
              {/* <span>{item.text}</span> */}
            </StyledNavLink>
          );
        })}
      </StyledIconContainer>
      <StyledAssetContainer>
        <StyledNavFooterImg
          height="25px"
          width="25px"
          src={geistTechLetteringWhite}
        />
      </StyledAssetContainer>
    </StyledSideNav>
  );
};

export default SideNav;
