import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import StackedLineChartOutlinedIcon from '@mui/icons-material/StackedLineChartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

export const navData = [
  {
    id: 0,
    icon: (
      <CottageOutlinedIcon
        sx={{
          fontSize: {
            xs: 20,
            s: 20,
            md: 30,
            lg: 35,
            xl: 35,
          },
          color: '#fff',
        }}
      />
    ),
    text: 'Home',
    link: '/',
  },
  // {
  //   id: 1,
  //   icon: (
  //     <SearchOutlinedIcon
  //       sx={{
  //         fontSize: {
  //           xs: 20,
  //           s: 25,
  //           md: 30,
  //           lg: 35,
  //           xl: 35,
  //         },
  //         color: '#fff',
  //       }}
  //     />
  //   ),
  //   text: 'Graph',
  //   link: 'graph',
  // },
  {
    id: 3,
    icon: (
      <StackedLineChartOutlinedIcon
        sx={{
          fontSize: {
            xs: 20,
            s: 25,
            md: 30,
            lg: 35,
            xl: 35,
          },
          color: '#fff',
        }}
      />
    ),
    text: 'Master Data',
    link: 'admin/transactions',
  },
  {
    id: 4,
    icon: (
      <EmailOutlinedIcon
        sx={{
          fontSize: {
            xs: 20,
            s: 25,
            md: 30,
            lg: 35,
            xl: 35,
          },
          color: '#fff',
        }}
      />
    ),
    text: 'Master Data',
    link: '',
  },
  {
    id: 5,
    icon: (
      <GroupsOutlinedIcon
        sx={{
          fontSize: {
            xs: 20,
            s: 25,
            md: 30,
            lg: 35,
            xl: 35,
          },
          color: '#fff',
        }}
      />
    ),
    text: 'Integrations',
    link: '/integrations',
  },
  {
    id: 6,
    icon: (
      <SettingsOutlinedIcon
        sx={{
          fontSize: {
            xs: 20,
            s: 25,
            md: 30,
            lg: 35,
            xl: 35,
          },
          color: '#fff',
        }}
      />
    ),
    text: 'Settings',
    link: 'settings',
  },
];
