import styled from 'styled-components';
import { Box, Paper, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { devices } from '../../styles/Device';

export const StyledSideNav = styled(Box)`
  height: 90vh;
  width: 90px;
  /* background-color: var(--color-geist-purple); */
  opacity: 0.85;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  margin-top: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
  gap: 1em;

  @media ${devices.laptop} {
    gap: 2em;
  }

  @media ${devices.laptopL} {
    gap: 4em;
  }
  /* border: 5px solid gold; */
`;

export const StyledIconContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  gap: 2em;
  /* border: 1px solid red; */

  @media ${devices.laptop} {
    gap: 2em;
  }

  @media ${devices.laptopL} {
    gap: 2.5em;
  }

  @media ${devices.desktop} {
    gap: 3.5em;
  }
`;

export const StyledIconBox = styled(Box)`
  /* border: 1px solid red; */
`;

export const StyledAssetContainer = styled(Box)`
  /* width: 20px; */
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 20px; */
`;

export const StyledNavImg = styled.img`
  height: 65px;
  width: 65px;
`;

export const StyledNavFooterImg = styled.img`
  height: 45px;
  width: 45px;
`;

export const StyledNavLink = styled(NavLink)``;
