import { createSlice } from '@reduxjs/toolkit';

export interface DashboardState {
  activeChart: string;
  companyName: string;
}

const initialState: DashboardState = {
  activeChart: 'bar',
  companyName: 'Ash and Barrel',
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    changeChart: (state, action) => {
      state.activeChart = action.payload;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
