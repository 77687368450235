import React, { useState } from 'react';
import {
  StyledWrapperBox,
  StyledDashboardWrapper,
  StyledCardContainer,
  StyledGrid,
  StyledChartIconContainer,
  StyledChartContainer,
} from './DashboardElements';
import SideNav from '../../components/sideNav/SideNav';
import { useDrop } from 'react-dnd';
import StandardCard from '../../components/cards/StandardCard';
import PermanentDrawerRight from '../../components/Drawer/PermDrawer';
import BarChart from '../../components/charts/BarChart';
import LineChart from '../../components/charts/LineChart';
import { useTheme } from '@mui/material';
import { tokens } from '../../styles/Theme';
import CardProvider from '../../utils/CardProvider';
import BarChartIcon from '@mui/icons-material/BarChart';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../redux/store';
import { dashboardActions } from '../../redux/dashboardSlice';

interface DraggableCard {
  id: number;
}

const Dashboard = () => {
  const cardProvider = CardProvider;
  const [dashboard, setDashboard] = useState([...cardProvider]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'card',
    drop: (item: DraggableCard) => addItemToBoard(item.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const dispatch = useDispatch();

  const activeChart = useSelector(
    (state: RootState) => state.dashboard.activeChart
  );

  const toggleBarChart = () => {
    if (activeChart !== 'bar') {
      dispatch(dashboardActions.changeChart('bar'));
    }
  };

  const toggleLineChart = () => {
    if (activeChart !== 'line') {
      dispatch(dashboardActions.changeChart('line'));
    }
  };

  const addItemToBoard = (id) => {
    const cardList = cardProvider.filter((card) => id === card.id);
    setDashboard((dashboard) => [...dashboard, cardList[0]]);
  };

  return (
    <StyledGrid container>
      <StyledGrid item xs={1}>
        <SideNav />
      </StyledGrid>
      <StyledGrid item xs={8}>
        <StyledWrapperBox>
          <StyledDashboardWrapper>
            <StyledCardContainer
              sx={{
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                  md: 'row',
                  lg: 'row',
                  xl: 'row',
                },
                flexWrap: 'wrap',
              }}
              ref={drop}
            >
              {dashboard.map((card) => {
                return (
                  <StandardCard
                    info={card.cardInfo}
                    descriptor={card.cardDescriptor}
                    id={card.id}
                    key={card.id}
                  />
                );
              })}
            </StyledCardContainer>

            <StyledChartContainer height="55vh">
              <StyledChartIconContainer>
                <IconButton onClick={toggleBarChart}>
                  <BarChartIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton onClick={toggleLineChart}>
                  <SsidChartIcon sx={{ fontSize: 30 }} />
                </IconButton>
              </StyledChartIconContainer>
              {activeChart === 'bar' && <BarChart />}
              {activeChart === 'line' && <LineChart />}
            </StyledChartContainer>
          </StyledDashboardWrapper>
        </StyledWrapperBox>
      </StyledGrid>
      <StyledGrid item xs={3}>
        <PermanentDrawerRight />
      </StyledGrid>
    </StyledGrid>
  );
};

export default Dashboard;
