import React from 'react';

const CardProvider = [
  {
    id: 1,
    cardType: 'standardCard',
    cardInfo: '$87.54',
    cardDescriptor: 'Average Spend',
  },
  {
    id: 2,
    cardType: 'standardCard',
    cardInfo: '32',
    cardDescriptor: 'Return Customers',
  },
  {
    id: 3,
    cardType: 'standardCard',
    cardInfo: '45',
    cardDescriptor: 'Survey Responses',
  },
  {
    id: 4,
    cardType: 'standardCard',
    cardInfo: '$2,345.00',
    cardDescriptor: 'Monthly Return',
  },
];

export default CardProvider;
