import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { buildQueries } from '@testing-library/react';

export const chartApi = createApi({
  reducerPath: 'chartApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.geistly.io/' }),
  endpoints: (builder) => ({
    getAllBarChartData: builder.query({
      query: () => 'general/barchart',
    }),
    getAllLineChartData: builder.query({
      query: () => 'general/linechart',
    }),
  }),
});

export const { useGetAllBarChartDataQuery, useGetAllLineChartDataQuery } =
  chartApi;
